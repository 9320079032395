import React from 'react';
import ReactDOM from 'react-dom';
import App, { UpgradeBrowser } from 'views/App'
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { store, persistor } from 'redux/initializer';
import * as Yup from 'yup';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGoogle, faFacebookF, faAppStore, faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons'
import { faMobileAlt, faEnvelope, faSignInAlt, faAt, faSpinner, faHome, faUtensilsAlt, faRulerCombined, faFileAlt,
    faDumbbell, faRss, faBars, faShoppingBasket, faPlus, faBarcode, faRandom, faChevronUp, faChevronDown, faUniversalAccess,
    faInfoCircle, faExclamationCircle, faExclamationTriangle,  faUser, faUsers, faHistory, faImage, faWalking, faBadgeCheck,
    faCheck, faMinus, faSearch, faAngleRight, faChevronRight, faChevronLeft, faChevronCircleRight, faChevronCircleLeft, faAngleDown, 
    faTimes, faQuestionCircle, faArrowRight, faEye, faCheckCircle, faCreditCard, faArrowCircleRight, faLink, faLightbulbOn,
    faLock, faLockOpen, faStar, faHeart, faThumbsDown, faBan, faUndo, faPencilAlt, faListAlt, faFire, faTrash, faHeadSideBrain,
    faMedal, faSnooze, faPlayCircle, faLevelDownAlt, faLevelUpAlt, faVolumeUp, faVolumeMute, faHeartRate, faMeat, faMortarPestle,
    faEllipsisH, faEllipsisV, faPlay, faCloudUploadAlt, faArrowUp, faArrowDown, faCalculator, faPaperPlane, faHeartbeat,
    faCog, faDotCircle, faList, faExchangeAlt, faPause, faStepForward, faRedo, faBurgerSoda, faPlusCircle, faBalanceScaleLeft,
    faTachometerAlt, faHandPointer, faCalendarAlt, faCloudDownloadAlt, faArrowsAltV, faThLarge, faRunning, 
    faSignOutAlt, faChartLine, faChartPie, faSortAmountDownAlt, faUserPlus, faBell, faAward, faExternalLinkAlt,
    faUserFriends, faThumbtack, faPencil, faCircle, faTrophy, faAppleAlt, faCommentsAlt, faCalendarCheck, faIdCardAlt } from '@fortawesome/pro-solid-svg-icons'
import { faHome as falHome, faUtensilsAlt as falUtensilsAlt, faShoppingBasket as falShoppingBasket, faDumbbell as falDumbbell, 
    faBars as falBars, faMedal as falMedal, faSearch as falSearch, faUserPlus as falUserPlus, faTrash as falTrash, faUsers as falUsers, faAppleAlt as falAppleAlt,
    faCommentsAlt as falCommentsAlt, faCalendarCheck as falCalendarCheck, faIdCardAlt as falIdCardAlt, faChartLine as falChartLine, faAward as falAward, faPenNib as falPenNib } from '@fortawesome/pro-light-svg-icons'
import { faHeart as farHeart, faThumbsDown as farThumbsDown, faClock as farClock, faClipboardCheck as farClipboardCheck, faFileImport as farFileImport,
        faEdit as farEdit, faThumbsUp as farThumbsUp, faCopy as farCopy, faLock as farLock, faUserFriends as farUserFriends, faFileAlt as farFileAlt,
        faListAlt as farListAlt, faHistory as farHistory, faDollarSign as farDollarSign, faShoppingBasket as farShoppingBasket,
        faFilter as farFilter, faTags as farTags, faShareSquare as farShareSquare, faUsers as farUsers, faFileExport as farFileExport,
        faPaperPlane as farPaperPlane, faDownload as farDownload, faPencil as farPencil, faFolderDownload as farFolderDownload, 
        faFolderUpload as farFolderUpload, faBell as farBell, faBellSlash as farBellSlash, faVideo as farVideo, faCamera as farCamera,
        faBullseyeArrow as farBullseyeArrow, faUniversalAccess as farUniversalAccess, faChartPie as farChartPie, faUtensilsAlt as farUtensilsAlt,
        faChartLine as farChartLine, faDumbbell as farDumbbell, faPizzaSlice as farPizzaSlice, faSalad as farSalad, faIdCard as farIdCard
    } from '@fortawesome/pro-regular-svg-icons'
import 'assets/App.scss';
import 'rc-slider/assets/index.css';
import 'assets/js/waves';
import 'assets/js/cards';
import { PersistGate } from 'redux-persist/integration/react';
import initApp from 'config/initializers';
import i18n, { languageDetector } from 'config/i18n';
import { setLocale } from 'lib/api';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/it';
import 'moment/locale/de';

library.add(faMobileAlt, faEnvelope, faRss, faGoogle, faApple, faFacebookF, faSignInAlt, faAt, faSpinner, faHome, faUtensilsAlt, faTrash,
    faDumbbell, faBars, faShoppingBasket, falHome, falUtensilsAlt, falShoppingBasket, falDumbbell, falBars, faPlus, faMinus, faBarcode, faMeat,
    faInfoCircle, faExclamationTriangle, faExclamationCircle, faCheck, faSearch, faAngleRight, faChevronRight, faRandom, farUserFriends,
    faAngleDown, faChevronLeft, faTimes, faQuestionCircle, faArrowRight, faEye, faCheckCircle, faUser, faUsers, faPencil, farFileImport,
    faCreditCard, faLock, faLockOpen, faStar, faHeart, faThumbsDown, farHeart, farThumbsDown, farThumbsUp, faBan, faBurgerSoda, farPencil,
    faUndo, faPencilAlt, faMedal, falMedal, faSnooze, faPlayCircle, faLevelDownAlt, faLevelUpAlt, faVolumeUp, faVolumeMute, faCircle, faLink,
    faEllipsisH, faEllipsisV, faPlay, faCloudUploadAlt, faArrowUp, faArrowDown, faCalculator, faCog, faDotCircle, farLock, faExternalLinkAlt,
    faList, farListAlt, faListAlt, faExchangeAlt, farClock, farClipboardCheck, farEdit, faPause, faStepForward, faTachometerAlt, faAppStore, faGooglePlay,
    faHandPointer, faCalendarAlt, faCloudDownloadAlt, faArrowsAltV, farCopy, faRedo, faSignOutAlt, faChartLine, faThumbtack, farFilter, farFileAlt,
    faChartPie, faRulerCombined, faSortAmountDownAlt, falSearch, faUserPlus, falUserPlus, faBell, faAward, falAward, falTrash, faUserFriends,
    faChevronDown, faChevronUp, farHistory, farDollarSign, farShoppingBasket, faTrophy, faThLarge, faChevronCircleRight, faChevronCircleLeft,
    faAppleAlt, faCommentsAlt, faCalendarCheck, faIdCardAlt, falCommentsAlt, falCalendarCheck, falUsers, falAppleAlt, falIdCardAlt, farCamera,
    farTags, farShareSquare, falChartLine, farUsers, faArrowCircleRight, faHistory, faPlusCircle, farFileExport, farPaperPlane, farDownload,
    farFolderDownload, farFolderUpload, farBell, farBellSlash, faImage, faPaperPlane, faRunning, faHeartRate, faFire, faWalking, farVideo, faFileAlt, 
    faBadgeCheck, faUniversalAccess, falPenNib, farBullseyeArrow, farUniversalAccess, farChartPie, farUtensilsAlt, farChartLine, farDumbbell,
    faLightbulbOn, faHeartbeat, faHeadSideBrain, faMortarPestle, faBalanceScaleLeft, farPizzaSlice, farSalad, farIdCard )

window.reduxStore = store;
initApp(store);

const setYupLocale = () => {
    Yup.setLocale({
        mixed: {
            required: i18n.t('Required')
        },
        string: {
            email: i18n.t('not a valid email'),
            min: ({ min }) => i18n.t("must be at least chars long",{count: min}),
            max: ({ max }) => i18n.t("must be at most chars long", {count: max})
        },
        number: {
            moreThan: ({ more }) => i18n.t('must be greater than',{ count: more }),
            lessThan: ({ less }) => i18n.t('must be less than',{count: less }),
            integer: i18n.t("must be an integer"),
            min: ({ min }) => i18n.t('must be at least',{count: min}),
            max: ({ max }) => i18n.t("must be no more than",{count: max})
        }
    })
}

let startLng = languageDetector.detect();
setLocale(startLng);
moment.locale(startLng);

i18n.on('languageChanged',(lng) => {
    setLocale(lng);
    moment.locale(startLng);
    setYupLocale();
})

i18n.on("loaded",() => {
    setYupLocale();
})

if(Object.entries) {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>, 
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <UpgradeBrowser />, 
        document.getElementById('root')
    );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
