import React from 'react';
import * as _ from 'lib/utilities';
import { FemaleGraphic, MaleGraphic } from 'partials/AnimatedBodySvgs';

export function CircularProgressSmall(props) {
    return (
        <div style={{overflow: 'visible', transform: 'scale(0.5,0.5)', height: '0', width: '40px'}}>
            <div className="preloader-wrapper active">
                <div className={"spinner-layer spinner-" + (props.color || 'primary') + "-only"}>
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                        <div className="circle"></div>
                    </div><div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function CircularProgress(props) {
    return (
        <div style={{overflow: 'hidden'}}>
            <div className="preloader-wrapper active">
                <div className={"spinner-layer spinner-" + (props.color || 'primary') + "-only"}>
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                        <div className="circle"></div>
                    </div><div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PercentageLoadingCircle = ({ pctDone, strokeWidth=1.5, bcgColor='#22222222', successColor='#4CAF50' }) => {

    return (
        <svg viewBox="0 0 36 36" style={{ width: '100%', display: 'inline-block', maxWidth: '200px', height: 'auto'}}>
            <path stroke={bcgColor} strokeWidth={strokeWidth} fill="none" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
            <path strokeDasharray={`${pctDone}, 100`} stroke={successColor} strokeWidth={strokeWidth} fill="none" strokeLinecap='round' d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"></path>
            <text x="18" y="18" className="pct-circle-text" dominantBaseline={'middle'}>{pctDone}%</text>
        </svg>
    )
}

const BodyLoadingSvg = ({ gender }) => {

    return (
        <div className="inline-block" style={{ maxHeight: '70vh', height: '70vh' }}>
            {gender === 'f' && <FemaleGraphic style={ { maxHeight: '100%'} } />}
            {gender === 'm' && <MaleGraphic style={ { maxHeight: '100%'} }  />}
        </div>
    )
}

export class PsuedoPctLoadingBlock extends React.Component {
     constructor(props) {
        super(props);
        const { load } = this.props;
        this.state = { pctDone: 0 };
        this.timeoutId = null;
        load && load();
     }

     componentDidMount() {
        this.incrementPercent();
     }

     render() {
        const { subtitles, subtitleSwitchPcts, useBody } = this.props;
        const { pctDone } = this.state;
        const subIndex = _.findIndex(subtitleSwitchPcts,pct => (pctDone < pct));
        const subtitle = subIndex === -1 ? subtitles[subtitles.length-1] : subtitles[subIndex];
    
        return (
            <div className="text-center">
                {!useBody && (<PercentageLoadingCircle   
                    pctDone={pctDone}
                />)}
                {useBody && (<BodyLoadingSvg gender={useBody} />)}
                <div className="pct-circle-title">{useBody ? `${pctDone}% ` : ''}{subtitle}</div>
            </div>
        )
     }

     incrementPercent = () => {
        const { pctDone: prevPctDone } = this.state;
        const { duration, successCallback } = this.props;
        const medianSwitchTime = Math.floor((duration*1000)/100);
        const minSwitchTime = 0;
        const maxSwitchTime = medianSwitchTime*2;

        if(prevPctDone < 100) {
            this.timeoutId = setTimeout(this.incrementPercent, _.random(minSwitchTime,maxSwitchTime));
            this.setState({ pctDone: prevPctDone+1 });
        } else {
            successCallback && setTimeout(successCallback,1);
        }
     }

     componentWillUnmount() {
        clearTimeout(this.timeoutId);
     }
}