import React, { createRef } from 'react';

class PinchZoomCanvas extends React.Component {

    constructor(props) {
        super(props);
        this.lastPosRef = createRef(null);
        this.canvasRef = createRef(null);
        this.containerRef = createRef(null);
        this.cancelClickRef = createRef(null);

        this.state ={ image: null, scale: 1, position: { x: 0, y: 0 }, startDistance: null };
    }

    componentDidMount() {
        this.loadImage();
        this.adjustCanvasSize();
        window.addEventListener('resize', this.adjustCanvasSize);
        window.addEventListener('mousemove', this.handleMouseMove);
        window.addEventListener('mouseup', this.handleEnd);
        window.addEventListener('touchend',this.handleEnd);
        window.addEventListener('touchcancel',this.handleEnd);
        if(this.canvasRef.current) {
            this.canvasRef.current.addEventListener('wheel',this.handleMouseWheel);
        }
    }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.imageUrl !== this.props.imageUrl) {
            this.loadImage();
        }
        
        const { image, position, scale } = this.state;
        if(image !== prevState.image || position !== prevState.position || scale !== prevState.scale) {
            this.adjustCanvasSize();
        }

        if(prevState.scale !== scale) {
            this.ensureMinPos();
        }

    }

    render() {
        const { imageUrl, clickHandler, ...rest } = this.props;

        return (
            <div ref={this.containerRef} style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', top: 0 }} {...rest} >
              <canvas
                ref={this.canvasRef}
                onMouseDown={this.handleMouseDown}
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onClick={this.handleClick}
                style={{ width: '100%', height: '100%', cursor: 'grab' }}
              ></canvas>
            </div>
          );
    }

    getDims = (img) => {
        const { image } = this.state;
        img = img || image;
        const canvas = this.canvasRef.current;
        const { width: canvasWidth, height: canvasHeight } = canvas;
        const imgHeight = canvasHeight; // Make image height equal to canvas height
        const imgWidth = img ? (img.width * (imgHeight / img.height)) : canvasWidth;

        return { imgWidth, imgHeight, canvasWidth, canvasHeight };
    }

    getMins = () => {
        const { scale } = this.state;
        const { imgWidth, imgHeight, canvasWidth, canvasHeight } = this.getDims();
        const minX = canvasWidth-imgWidth*scale;
        const minY = canvasHeight-imgHeight*scale;

        return { minX, minY };
    }

    loadImage = () => {
        const { imageUrl } = this.props;
        const img = new Image();
        img.onload = () => {
          if(this.canvasRef.current) {
              const { imgWidth, canvasWidth } = this.getDims(img);
  
              this.setImage(img);
              // Reset scale and position
              this.setScale(1);
              this.setPosition({ x: (canvasWidth - imgWidth) / 2, y: 0 });
          }
        };
        img.src = imageUrl;
    };

    adjustCanvasSize = () => {
        const { image, position, scale } = this.state;
        if (this.containerRef.current && this.canvasRef.current) {
          const { width, height } = this.containerRef.current.getBoundingClientRect();
          this.canvasRef.current.width = width;
          this.canvasRef.current.height = height;
  
          if (!this.canvasRef.current || !image) return;
  
          const canvas = this.canvasRef.current;
          const ctx = canvas.getContext('2d');
          const { width: canvasWidth, height: canvasHeight } = canvas;
      
          // Clear the canvas
          ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      
          // Calculate image size and position to fit canvas height and center horizontally
          const imgHeight = canvasHeight; // Make image height equal to canvas height
          const imgWidth = image.width * (imgHeight / image.height);
      
          // Apply transformations and draw the image
          ctx.save();
          ctx.translate(position.x, position.y);
          ctx.scale(scale, scale);
          ctx.drawImage(image, 0, 0, imgWidth, imgHeight);
          ctx.restore();
        }
    };

    ensureMinPos = () => {
        const { minX, minY } = this.getMins();
        const { position: { x, y } } = this.state;

        const newY = Math.max(minY,y);
        const newX = Math.max(minX,x);

        if(newX !== x || newY !== y) {
            this.setPosition({ x: newX, y: newY });
        }
    }

    setImage = (image) => this.setState({ image });
    setScale = scale => this.setState({ scale });
    setStartDistance = startDistance => this.setState({ startDistance });
    setPosition = position => this.setState({ position });

    handlePan = (newX,newY) => {
        // Pan gesture
        if(this.lastPosRef.current) {
            const { position } = this.state;
            const { x, y } = this.lastPosRef.current;
            const movementX = newX - x;
            const movementY = newY - y;
            this.lastPosRef.current = { x: newX, y: newY };
            const { minX, minY } = this.getMins();
            this.cancelClickRef.current = true;

            this.setPosition({
                x: Math.max(Math.min(position.x + movementX,0),minX),
                y: Math.max(Math.min(position.y + movementY,0),minY)
            });
        }
    }

    // Handle touch events
    handleTouchMove = (e) => {
        const { startDistance, scale } = this.state;
        if (e.touches.length === 2) {
            // Pinch gesture
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];

            const distance = Math.sqrt(
                Math.pow(touch1.pageX - touch2.pageX, 2) +
                Math.pow(touch1.pageY - touch2.pageY, 2)
            );


            if (startDistance) {
                const scaleAdd = (distance - startDistance)/this.canvasRef.current.height;
                this.setScale(Math.min(Math.max(scale + scaleAdd,1),3));
            }
            this.setStartDistance(distance);
        } else if (e.touches.length === 1 && this.lastPosRef.current) {
            this.handlePan(e.touches[0].pageX,e.touches[0].pageY);
        }
    };

    handleMouseMove = (e) => {
        this.handlePan(e.pageX,e.pageY);
    }

    handleTouchStart = (e) => {
        if (e.touches.length === 2) {
            // Initialize pinch distance
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            const startDist = Math.sqrt(
                Math.pow(touch1.pageX - touch2.pageX, 2) +
                Math.pow(touch1.pageY - touch2.pageY, 2)
            );
            this.setStartDistance(startDist);
        } else {
            const touch1 = e.touches[0];
            this.handleStart(touch1.pageX,touch1.pageY);
        }
    };

    handleMouseDown = (e) => {
        this.handleStart(e.pageX,e.pageY);
    }

    handleMouseWheel = (e) => {
        e.preventDefault();
        const { scale } = this.state;
        const scaleAdd = -(e.deltaY*3)/this.canvasRef.current.height;
        const newScale = Math.min(Math.max(scale + scaleAdd,1),3);
        this.setScale(newScale);
    }

    handleStart = (x,y) => {
        this.lastPosRef.current = { x, y };
    }

    handleEnd = (e) => {
        this.lastPosRef.current = null;
    }

    handleClick = (e) => {
        const { clickHandler } = this.props;
        if(this.cancelClickRef.current) {
            this.cancelClickRef.current = false;
        } else {
            clickHandler && clickHandler(e);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustCanvasSize);
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleEnd);
        window.removeEventListener('touchend',this.handleEnd);
        window.removeEventListener('touchcancel',this.handleEnd);    
        if(this.canvasRef.current) {
            this.canvasRef.current.removeEventListener('wheel',this.handleMouseWheel);
        }
    }

}

export default PinchZoomCanvas;
