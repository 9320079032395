import React, { useEffect, useState } from "react";

const WordFadeInText = ({ lines, speed, WrapComp, finishCallback, ...wrapProps }) => {
  const [visibleWordCount, setVisibleWordCount] = useState(0);
  const Comp = WrapComp || 'p';
  const hasCalledFinish = React.useRef(false);

  // Convert speed from words per minute to milliseconds per word
  const intervalMs = 60000 / speed;

  // Flatten all words from lines into a single array
  const words = lines.flatMap((line) => line.split(" "));

  useEffect(() => {
    if (visibleWordCount < words.length) {
        const interval = setInterval(() => {
          setVisibleWordCount((prev) => prev + 1);
        }, intervalMs);
  
        return () => clearInterval(interval);
      } else if (visibleWordCount === words.length && finishCallback && !hasCalledFinish.current) {
        hasCalledFinish.current = true;
        finishCallback();
      }
  }, [visibleWordCount, words.length, intervalMs, finishCallback]);

  // Reconstruct paragraphs from the visible words
  const paragraphs = [];
  let wordIndex = 0;
  for (let i = 0; i < lines.length; i++) {
    const lineWords = lines[i].split(" ");
    const mapFnMaker = wordIndex => (word, idx) => {
        const globalIndex = wordIndex + idx;
        const isVisible = globalIndex < visibleWordCount;
        return (
          <span
            key={globalIndex}
            style={{
              opacity: isVisible ? 1 : 0,
              transition: `opacity 0.3s ease-in`,
              marginRight: "0.25em",
              display: "inline-block",
            }}
          >
            {word}
          </span>
        );
      }
    const visibleWords = lineWords.map(mapFnMaker(wordIndex));
    wordIndex += lineWords.length;
    paragraphs.push(
      <Comp key={i} {...wrapProps}>
        {visibleWords}
      </Comp>
    );
  }

  return <div>{paragraphs}</div>;
};

export default WordFadeInText;
