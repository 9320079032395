import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languageDetector = new LanguageDetector();

const baseUrl = process.env.PUBLIC_URL && process.env.PUBLIC_URL !== '/' ? process.env.PUBLIC_URL : '';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(languageDetector)
  .init({
    backend: {
        loadPath: baseUrl + '/locales/%{lng}/%{ns}13.json' //REMEMBER TO ALSO UPDATE RAILS CODE GENERATORS IF YOU CHANGE VERSION NUMBER
    },

    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
      prefix: '%{',
      suffix: '}'
    },

    load: 'languageOnly',

    whitelist: ['en'],

    detection: { checkWhitelist: true },

    react: {
        useSuspense: false
    }
  });

  export default i18n;