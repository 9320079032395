import React from 'react';
import { User } from 'lib/classes';
import { AutoAdvanceButton, FixedSizeQuestion, FlowSubtext, FullWidthNextButton } from 'components/FlowForm';
import { FlowAnswerButton } from 'components/Button';
import * as _ from 'lib/utilities';
import reverseAgeMetabolism from 'assets/img/reverse_age_mri.webp';
import aragonSchoenfeld from 'assets/img/AragonShcoenfeld.webp';
import { CalculatingScreen, filterSubmitValues, Illustration, metAnalysisInfo } from 'partials/OnboardingComponents';
import { CheckButtons, SelectButton } from 'components/Form';
import { brandName, citations } from 'config/settings';
import { Trans } from 'react-i18next';
import WordFadeInText from 'components/WordFadeInText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'components/Routing';
import SequentialFadeIn from 'components/SequentialFadeIn';
import { FadeIn } from 'components/FadeIn';

const metabolicTypeOpts = {
    [-1]: [
        'hardtolose2',
        'hardtolosestall',
        'hardtolosestubborn',
        'hardtolose1',
        'easytolose'
    ],
    0: [
        'hardtolosestubborn',
        'hardtochange',
        'easytochange'
    ],
    1: [
        'hardtobuild1',
        'easytobuildbutfat',
        'easytobuildlean'
    ]
}

export const MetabolicTypeQ = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;
    const options = metabolicTypeOpts[weightGoal] || metabolicTypeOpts[-1];

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("bodytypeq")} />
            {options.map((type, i) => {
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="bodyType" value={type} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`body-type-${i}-btn`}>
                            <span>{t(type)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const BodyChangeQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("bodychangeq")} />
            {['bodychangea1', 'bodychangea2'].map((change, i) => {
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="bodyChanged" value={change === 'bodychangea2'} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`body-change-${i}-btn`}>
                            <span>{t(change)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const BodyChangeWhenQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("bodychangewhenq")} />
            {['bodychangekids', 'bodychange20s', 'bodychange30s', 'bodychange40plus'].map((when, i) => {
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="bodyChangeWhen" value={when} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`body-change-when-${i}-btn`}>
                            <span>{t(when)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const HormoneProfileQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("hormoneprofileq")} />
            {['hormoneperi', 'hormonemeno', 'hormonepost', 'hormonenone'].map((profile, i) => {
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="hormoneProfile" value={profile} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`hormone-profile-${i}-btn`}>
                            <span>{t(profile)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const lowTSymptoms = [
    'lowt1',  // Decreased muscle mass
    'lowt2',  // Higher body fat
    'lowt3',  // Low libido
    'lowt4',  // Fatigue
    'lowt5',  // Low quality sleep
    'hormonenone'  // None of the above
];

export const LowTQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("lowtq")} className="mb30" />
            <div>
                <CheckButtons {...flowProps} name="lowTSymptoms" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {lowTSymptoms.map((symptom, i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={symptom} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(symptom)}</span>
                                </SelectButton>
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

export const GenericHormoneQ = ({ flowProps, t }) => {
    const { values: { gender } } = flowProps;
    
    if (User.female(gender)) {
        return <HormoneProfileQ flowProps={flowProps} t={t} />;
    } else {
        return <LowTQ flowProps={flowProps} t={t} />;
    }
}

export const StressLevelsQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("stressq")} />
            {['stress1', 'stress2', 'stress3', 'stress4'].map((level, i) => {
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="stressLevel" value={level} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`stress-level-${i}-btn`}>
                            <span>{t(level)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const MetabolicAgeScreen = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30 mt0 pt0" text={t('metabolicageq', { brand_name: brandName() })} />
            <Illustration src={reverseAgeMetabolism} width={480} height={348} subtitle={t('actualmri')}/>
            <SequentialFadeIn initialDelay={3000} delay={300}>
                <FlowSubtext className="mb10 text-left">{t('metabolicagesub1')}</FlowSubtext>
                <FlowSubtext className="mb10 text-left">
                    <Trans i18nKey="metabolicagesub2"><b></b></Trans>
                </FlowSubtext>
                <FlowSubtext className="mb10 text-left">
                    <Trans i18nKey="metabolicagesub3" values={{ brand_name: brandName() }}><b></b></Trans>
                </FlowSubtext>
                <FlowSubtext className="mb20 text-left">{t('metabolicagesub4')}</FlowSubtext>
            </SequentialFadeIn>
            <FadeIn delay={4000}>
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t('Got It')}
                />
            </FadeIn>
        </React.Fragment>
    )
}
  

export const MetabolicTypeScreen = ({ flowProps, t, submit }) => {
    const { gender, curBodyFat } = flowProps.values;
    const genderStr = User.female(gender) ? 'female' : 'male';
    const { type, fullName, descriptions } = metAnalysisInfo(flowProps.values,t);
    const [showNextBtn,setShowNextBtn] = React.useState(false);
    const [loaded,setLoaded] = React.useState(false);

    if(!loaded) {
        return (
            <CalculatingScreen 
                subtitles={[t('Finding similar users'),t('Running analysis'),t('Compiling results')]}
                subtitleSwitchPcts={[35,70]}
                successCallback={() => setLoaded(true)}
                load={() => submit(filterSubmitValues(flowProps.values))}
                useBody={genderStr[0]}
            />
        )
    }

    return (
        <React.Fragment>
            <FlowSubtext className="mb10">{t('You Have')}</FlowSubtext>
            <FixedSizeQuestion className="mb0 mt0 pt0" text={`${fullName} (${type})`} />
            <Illustration src={`${_.publicUrl()}img/body_fat_images/${genderStr}/before/${curBodyFat}.png`} width={280} height={320} className="mb10" />
            <div className="text-left">
                <WordFadeInText lines={descriptions} speed={500} WrapComp={FlowSubtext} className="mb10" finishCallback={() => setShowNextBtn(true)} />
            </div>
            {showNextBtn && (<FullWidthNextButton 
                flowProps={flowProps}
                label={t('seemettypekeys', { type })}
            />)}
        </React.Fragment>
    )
}

const metSciKeys = (values,t,type) => {
    const g = values.gender ? 'f' : 'm';
    const loseStr = values.weightGoal === 1 ? 'gain' : 'lose';

    return [
        { icon: 'chart-pie', title: t('typetargetedmacros',{ type }), desc: t(`metmacros${loseStr}`,{ type }), citation: citations.protein },
        { icon: 'dumbbell', title: t('anabolictraining'), desc: `${t('metstrengthpre',{ type })} ${t(`metstrength${loseStr}${g}`)}`, citation: citations.rt },
        { icon: 'heartbeat', title: t('lilornocardio'), desc: `${t(`metnocardio${loseStr}`, { type })}`, citation: citations.consistency },
        { icon: ['far','pizza-slice'], title: t('mfyl'), desc: t(`metmfyl${loseStr}`, { type }), citation: citations.consistency }
    ];

}

export const MetTypeSciKeys = ({ flowProps, t }) => {
    const { values: { weightGoal }, values } = flowProps;
    const { type } = metAnalysisInfo(values,t);

    let question = 'metscikeyslose';
    if(weightGoal === 1) {
        question = 'metscikeysgain';
    } else if(weightGoal === 0) {
        question = 'metscikeystone';
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t(question, { type })} />
            <div>
                <div className="onb-ol">
                    <SequentialFadeIn delay={1500}>
                    {metSciKeys(values,t,type).map(({ title, desc, icon, citation },i) => {

                        return (
                            <div key={i} className="onb-ol-li">
                                <div className="icon">
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                                <div className="title-cont">
                                    <label>{t(title)}{citation && (<Link to={citation} target="_blank"><sup>{i+1}</sup></Link>)}:</label> {t(desc, {brand_name: brandName() })}
                                </div>
                            </div>
                        )
                    })}
                    </SequentialFadeIn>
                </div>
            </div>
            <FadeIn delay={5000}>
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t("Got It")}
                />
            </FadeIn>
        </React.Fragment>
    )
}

export const MacroRecsReasonScreen = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;
    const goal = weightGoal === 1 ? t('muscle gain') : t('fat loss');
    const { type } = metAnalysisInfo(flowProps.values,t);

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('macrorecsexp')} />
            <Illustration src={aragonSchoenfeld} width={480} height={288} className="mb10" subtitle={'Dr. Aragon and Dr. Schoenfeld'}/>
            <SequentialFadeIn delay={600}>
                <FlowSubtext className="mb10 mt15 text-left"><Trans i18nKey="macrosapproach1"><b></b></Trans></FlowSubtext>
                <FlowSubtext className="mb10 text-left"><Trans i18nKey={_.curAbTestV() === 'b' ? "macrosapproach2met" : "macrosapproach2"} values={{ type }}></Trans></FlowSubtext>
                <FlowSubtext className="mb10 text-left"><Trans i18nKey="macroswhyflex" values={{ goal }}><b></b></Trans></FlowSubtext>
            </SequentialFadeIn>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Got It')}
            />
        </React.Fragment>
    )
}