import React from 'react';
import * as _ from 'lib/utilities';
import maleMusculature from 'assets/img/male-musculature.png';
import femaleMusculature from 'assets/img/female-musculature.png';

const bpartToMetric = {
    abdominals: 'waist',
    obliques: 'waist',
    biceps: 'arms',
    triceps: 'arms',
    'upper-chest': 'shoulders',
    'side-delts': 'shoulders',
    'front-delts': 'shoulders',
    'upper-traps': 'shoulders',
    hips: 'hips',
    'lower-chest': 'chest',
    'lats-and-teres-major': 'chest',
    'serratus': 'chest',
    thighs: 'thighs',
    forearms: 'forearms',
    calves: 'calves'

}

export const malePaths = {
    "forearms": [
      "m 237.0276,276.50598 7.5,-7.88 8.62,5.63 5.63,-13.75 7.43,-12.13 4.2,-15.87 1.62,11.75 2.12,15.68 2.26,4.95 2.41,31.17 1.09,63.33 -8.24,-2.71 -11.14,-1.05 -2.75,2.13 -15.52,-42.22 -4.11,-19.41 z",
      "m 66.777595,253.12598 -4.87,9.76 -3,8.87 -5.13,13.87 -0.87,10.76 -2.63,16 -8.25,43.26 6.88,0.36 13.37,5.12 11.63,-19.12 13,-21.5 7.62,-16.88 4.38,-15 v -17.5 l -11.76,-5.62 -15.87,12.92 -4.38,-11.67 z"
    ],
    "calves": [
      "m 103.5276,550.50598 5.5,33 2.75,18.5 2.25,22.75 2.5,17.5 8,48.25 -16.5,3.75 -2.5,-51.5 -4.5,-28.5 -2.500005,-31.5 z",
      "m 141.5276,560.00598 -4.75,9.25 -7.25,9.75 -3,8.75 -1.75,14.5 1,21.5 2,27.25 6.5,23 3.25,-24.5 1.25,-24 5.25,-11.25 2.5,-10 -1,-20 z",
      "m 187.7776,565.25598 9.25,16 3.5,10.75 1.5,27.25 -0.25,27.5 v 9.5 l -3.5,9.25 -3.5,-18.75 -1.25,-22.75 -7,-18.25 v -21.75 z",
      "m 220.2776,546.25598 -3.25,33.25 -2.75,15.5 -0.25,27.25 -3.25,11.5 -0.25,15.5 -6.25,40 15,3.75 4.75,-2.5 3.75,-70 3,-25.5 -1,-26 -3,-16.25 z"
    ],
    "triceps": [
      "m 98.907595,270.62598 4.370005,-10 -2.37,-26",
      "m 254.1476,215.25598 3,16.75 1.63,28.75 7.13,-12.83 4.25,-14.26 0.37,-13.66 -3.75,-12 -5.37,-13.96 z"
    ],
    "hips": [
        "M 101.027344 330.08789 L 98.777344 340.28516 L 96.992188 359.18359 L 92.847656 382.12891 L 108.054688 375.06445 L 121.710938 371.11133 L 132.9375 369.24219 L 127.849609 358.87109 L 126.703125 348.10547 L 121.777344 342.00586 L 103.777344 331.50586 L 101.027344 330.08789 z",
        "M 219.52734 326.00586 L 215.27734 331.00586 L 189.02734 342.75586 L 185.77734 348.50586 L 184.81055 357.59766 L 181.08008 368.20898 L 193.75 369.06641 L 210.48047 372.1582 L 226.88281 378.41211 L 224.06836 360.32617 L 223.27734 336.50586 L 219.52734 326.00586 z"
      ],
      "thighs": [
        "M 210.4795 372.15889 L 193.74982 369.06681 L 181.07933 368.20885 L 170.52734 388.25586 L 164.02734 400.50586 L 167.02734 410.00586 L 166.02734 422.00586 L 166.52734 433.25586 L 168.77734 462.25586 L 172.27734 477.50586 L 175.52734 496.00586 L 177.02734 507.25586 L 179.52148 519.98047 L 179.52748 520.25586 L 182.77748 528.75586 L 184.02748 545.75586 L 187.77748 556.00586 L 188.02748 561.25586 L 199.02748 576.25586 L 203.77748 567.00586 L 197.02748 559.25586 L 191.02748 546.75586 L 187.90248 525.63086 L 195.77748 530.25586 L 201.02748 527.00586 L 203.27748 515.75586 L 203.52748 503.25586 L 215.27748 504.25586 L 217.02748 519.25586 L 222.52748 532.25586 L 226.02748 525.75586 L 228.27748 507.25586 L 233.27748 497.00586 L 237.27748 478.00586 L 238.52748 445.50586 L 234.40248 423.13086 L 232.77748 407.00586 L 229.02748 389.00586 L 226.88274 378.41134 z",
        "m 88.130478,412.14352 -1.292471,4.75958 -3.060411,19.35288 -1.75,21.75 3.25,28.25 6.75,16 4.75,8.5 2.75,15.75 4.000004,-10.25 v -10 l -2.5,-5.25 h 11 l 3,11.5 3,11.75 4.75,6 h 5.5 l 6.41015,-4.68555 -3.66015,23.18555 -10.5,15 5,12.75 13,-14.25 4.24999,-24.25 0.75,-7.25 4.75,-20.5 2.75,-21.75 4.75,-27.75 1,-24.25 -1.75,-15.75 v -14.25 l 3,-3.75 -11.75,-11.25 -10.72851,-16.21875 -0.0215,-0.28125 -2.59092,-5.76318 -11.22607,1.86779 -13.6567,3.95396 -15.206913,7.06463 -3.819381,26.8768 z m 49.324642,-33.47804 c -63.733241,282.68872 -31.86662,141.34436 0,0 z"  
    ],
    "biceps": [
      "m 94.777595,180.25598 -8.24,8.31 -6.81,15.93 -3.45,8.51 -4.63,12.38 -2.37,9.62 -2,8.12 v 11 12.38 l 4.4,11.68 8.23,-6.56 7.13,-5.88 11.77,5.29 0.64,-7.56 1.200005,-27.09 -1.000005,-24.76 -6.12,-18.12 z",
      "m 254.2776,215.75598 2.75,16.5 1.62,28.63 -5.5,13.5 -8.5,-5.76 -7.37,8 v -20.24 l -4.87,-18.63 -4,-17.37 v -31.38 l 0.98,-9.31 18.02,23.81 z"
    ],
    "side-delts": [
      "m 247.5276,203.38598 6.88,12.24 6.62,-21.87 3.25,-19.37 -0.96,-9.63 -2.54,-7.5 -4.5,-7 -7.87,-6.25 -7.12,-3.5 -10.76,-3.5 h -4.62 l -4,3.5 6.74,0.12 10.63,5.76 7.13,5.5 4.24,12.74 1.38,13.63 z",
      "m 103.6476,145.62598 -7.740005,-0.12 -5.76,3.25 -5.12,3.75 -5.75,7 -4.25,6.88 -2.62,9.74 v 10.26 l 1,8.37 2,9 1.26,8.19 2.86,-6.53 -2.62,-15.55 v -13.61 l 1.37,-9.13 4.07,-7 4.8,-6 7.38,-4.87 z"
    ],
    "front-delts": [
      "m 185.5276,146.12598 29.25,-3.37 7.37,-1.87 h 6.76 l 10.37,5.5 7.05,5.72 4.08,12.4 1.5,13.75 -4.26,25.37 -18.5,-24.37 -1.24,-8.87 -22.88,-21.88 z",
      "m 115.6476,148.50598 -12.12,-2.88 -9.000005,3.63 -7.12,4.63 -4.5,5.24 -4.5,7.88 -1.5,8.88 v 13.24 l 2.62,15.5 3.5,-7.8 3.5,-8.2 8.25,-8.5 1,-9.74 2,-10.5 z"
    ],
    "upper-chest": [
      "m 163.1476,150.38598 22.13,-4.26 20.13,2.63 22.37,21.63 1.25,8.87 -26.12,-19.5 -17.38,-2.63 z",
      "m 115.6476,149.00598 19.63,-0.5 7.13,2.88 -37.26,12.12 -9.620005,6.88 2.12,-10.26 z"
    ],
    "lower-chest": [
      "m 163.1476,150.25598 21.88,6.75 17.62,2.75 18.26,13.75 v 15.75 l -1.38,4.25 -6.75,11.25 -10.23,10.02 -19.9,3.73 h -7.5 l -17.74,-5.62 -5.98,-3.14 -3.96,-9.1 1.31,-16.89 6.25,-20.87 z",
      "m 95.527595,170.62598 9.120005,-6.87 37.63,-12.37 1.37,1.74 v 31.38 l -2.24,15.25 -6.13,10.5 -4.63,3.5 -12.37,6.5 -5.63,1 h -4.5 l -4.5,-3.13 -4.120005,-6.5 -5.75,-17.87 z"
    ],
    "abdominals": [
      "m 118.2776,220.38598 12.28,-6.7 25.72,-1.06 18.5,5.88 4.63,14.5 v 17.38 5.62 l 2,9.89 3.24,11.86 v 15.87 l -1,20.13 -1,7.63 -5.24,10.5 v 17.87 l -11.88,40.13 -13.99,-0.29 -5.14,-5.81 -10.62,-24.78 -2.25,-11.97 v -13.28 l -10.62,-17.37 -1.26,-14.63 -3,-9 -2,-35.25 -3.28,-8.39 -0.3,-13.72 z"
    ],
    "upper-traps": [
      "m 186.7776,110.62598 -5.5,11.5 11.25,20.26 h 11.62 l 13.76,-7.5 -10.29,-3.88 -7.59,-4.25 -5.38,-3.63 -4.74,-5.62 z",
      "m 110.3376,141.20598 3.57,3.64 4.12,1.04 17.12,-0.13 2.76,-2.75 0.94,-13.75 -14.2,8.13 -5.86,2.89 z"
    ],
    "obliques": [
      "m 105.36054,229.59646 7.91706,-8.35048 -3.76,4.88 2.39,1.88 -1,8 v 4.38 l -3.63,1.87 2.69,2.69 1.06,6.18 1,8 -3.38,1.26 3.38,2 0.88,3.74 1.24,14.76 -2.87,1 3.2,6.15 v 3.97 l 2.55,5.88 -5,5 7.27,6.99 1.23,12.13 5.12,11.38 2.63,5.62 -6.63,0.25 -10.67,-6.18 -8.33,-6.23 2.26,-4.96 -2.5,-9.13 -0.57,-16.11 3.56,-13.15 -0.37,-13.81 -1.62,-9.3 -2.76,-24 z",
      "m 181.2776,218.69598 3.64,3.62 -2.51,2.88 v 8.12 l 2,4.13 2.87,1.13 -2.87,3.37 v 14.87 l 5.12,2.5 -3.38,2.26 1.13,8.5 1.5,8.5 4.63,1.24 -1.63,5.38 -3,2.62 1,11.63 7.37,1.13 -8.5,5.62 -2,14.38 -4,12.37 v 11.5 l 1.26,3 2.62,-6.87 7.25,-4.13 18,-6.75 5.63,-7.12 1.12,-11.88 v -11.25 l -2.5,-10.37 0.88,-16.07 -6.01125,-20.46506 2.25308,-5.28567 -6.51723,-6.29762 -1.16549,-3.13451 6.4242,-6.33906 -8.06543,-0.53458 z"
    ],
    "lats-and-teres-major": [
      "m 228.1476,186.50598 -4.44,6.42 -1.16,7.21 -1.9,6.49 -1.74,25 -1,15.63 -2,9.13 1,16.62 9.74,-22.09 3.54,-9.72 2.22,-2.69 -4.13,-18.6 z"
    ],
    "serratus": [
      "m 228.9176,179.14598 -8.12,-5.62 0.12,15.92 -1.37,4.08 -7.37,11.5 -9.63,9.75 -21.5,4.25 10.55506,5.47526 12.22282,6.44824 8.06543,0.53458 -6.4242,6.33906 1.16549,3.13451 6.51723,6.29762 -2.25308,5.28567 6.01125,20.46506 0.5,-12.875 0.5,-12.875 2.27,-40.73 2.12,-6.12 0.75,-7.38 4.87,-6.88 z",
      "m 100.0176,211.88598 3.82,6.41 4.31,2.96 5.37,0.12 -8.15706,8.22048 -4.71294,6.78952 0.19098,-11.91268 z"
    ]
  }
  
export const femalePaths = {
  "forearms": [
    "m 220.28103,277.5846075 5.3816250000000005,-10.010025 4.18314,2.8737075 3.7969275,2.9848575 3.27045,-9.6995625 1.992795,-15.4871175 2.1687675,15.148485 5.6808375,18.838485 3.1417425000000003,22.9467975 2.4116325,31.770412500000003 0.3273825,25.104532499999998 -0.5809725,8.0372475 -17.3264475,-0.5820075 -6.389587500000001,-26.6670375 -6.932264999999999,-20.4529125 -4.5353025,-19.85367 -3.851025,-22.1595375 z",
    "m 87.131595,277.43886 -5.082,-9.7104075 -4.4827650000000006,4.97106 -3.7969275,2.9848575 -3.27045,-9.6995625 -1.992795,-15.4871175 -2.46838875,14.249617500000001 -4.18272975,22.1343225 -4.340229,20.5498275 -0.6139034999999999,31.770412500000003 -0.0277575,14.917402499999998 -0.9171352500000001,12.831195000000001 16.72720425,-0.5820150000000001 6.3895845,-21.273847500000002 6.932264999999999,-20.4529125 4.5353025,-19.85367 3.851025,-22.1595375 z"
  ],
  "calves": [
    "m 197.2291725,605.044545 10.7058525,11.408325 6.0348524999999995,20.7827775 1.6875,30.65625 -0.28125,30.9375 0.42822000000000005,10.1978175 -3.20964,-15.5910075 -7.099057500000001,-23.4963975 -7.875,-36.7108125 -0.5992425,-16.079347499999997 z",
    "m 229.2973275,573.1827900000001 -2.4577649999999998,22.7247975 -0.9963975,22.531064999999998 -0.8804925000000001,33.9520875 -0.9596549999999999,15.33447 -0.580875,24.3288 0.75891,29.120055 12.08106,1.522155 5.34375,-0.11590500000000001 0.4614525,-41.472345000000004 1.659945,-32.18409 -1.1193225,-34.3803075 -3.5219775,-25.654545 -4.8731025,-15.28503 z",
    "m 120.67467,590.3630850000001 -8.3088825,11.70795 -6.0348524999999995,20.7827775 -1.6875,30.65625 0.28125,30.9375 1.6691325,5.1042525 1.1122874999999999,-10.4974425 7.997925,-40.275202500000006 3.97992,-20.53125 1.7977275000000001,-15.779729999999999 z",
    "m 90.10461749999999,566.5911150000001 2.4577649999999998,22.7247975 3.09375,26.72577 2.67822,24.6638175 1.25928,18.6303075 1.479735,19.235235 -0.45928500000000005,14.138977500000001 -6.687877500000001,-0.5751975 -7.1414775,-1.0147724999999999 -3.1580475,-20.798460000000002 -2.8584300000000002,-31.285222500000003 -3.375,-37.37652 3.22236,-25.354927500000002 1.8768825,-8.693355 z"
  ],
  "thighs": [
    "m 221.1814425,395.8115625 -13.7370375,-2.8489050000000002 -40.015927500000004,-0.40429499999999996 -7.1582775000000005,10.0408875 1.9757625,12.582765 2.7757575,15.181822499999999 4.549440000000001,18.330015 4.9098525,14.9834025 3.9375,26.1449025 3.65625,33.096982499999996 2.5863675,23.4426225 6.1017375000000005,22.1053425 5.9991825,17.98749 0.36041249999999997,10.161742499999999 2.6047350000000002,6.5408925 8.4134475,7.3365525 5.9740649999999995,-0.6854250000000001 -1.4075925,-11.289427499999999 -6.9407325,-8.3088975 -8.4926175,-11.714962499999999 -6.75,-16.1598525 0.67908,-4.58985 6.162780000000001,1.308045 5.3070075,-2.7573825 -0.165345,-17.1505725 6.2736825,-5.073855 6.9266925,2.9227275 6.163455000000001,10.283325 4.6893899999999995,11.9284125 4.237125,1.0769025 -0.46497,-4.9325624999999995 0.23181749999999998,-9.13428 0.9045375,-20.1765075 0.50739,-33.2666625 0.7525575,-23.973390000000002 1.168095,-19.93836 -2.1213975,-23.845455 0.19494,-24.0114375 z",
    "m 75.838275,402.036105 24.314332500000003,-8.0416575 20.749492500000002,-3.2741849999999997 16.088475,-1.3674 3.4311825000000002,8.486715 5.8281600000000005,4.89126 -3.674625,28.664790000000004 -4.549440000000001,18.330015 -2.8125,17.3803725 -2.4393975,27.3433875 -0.9596549999999999,30.10077 -2.5863675,23.143005000000002 -7.0005975,21.206475 -2.1041025,6.302250000000001 -0.36041249999999997,10.161742499999999 -5.30133,9.836730000000001 -3.6195075,2.542605 -3.5770875,-5.77899 3.8045625000000003,-6.1958625000000005 4.2441375,-6.8107875 2.500185,-7.22064 4.9522725,-16.759095 -6.072262499999999,-1.5936374999999998 -1.36884,-8.2798425 -5.90625,-5.1543525 -4.3289775,-3.9672300000000003 -5.374815,3.6151725 -1.8331275000000002,7.41705 -4.3657275,6.3882449999999995 -4.989015,8.3329575 -3.3382575,-1.02045 0.46497,-12.423105 -0.23181749999999998,-13.6286025 -0.9045375,-12.3863475 -2.90436,-30.5700675 -0.7525575,-23.0745225 -2.666205,-20.237985 -2.372925,-22.347345 0.7039275,-9.6296025 z"
  ],
  "hips": [
    "m 213.77184,323.004435 -10.4740575,12.216675000000002 -8.55774,8.424809999999999 -12.345277500000002,20.55096 -3.78423,8.730165 -11.182057499999999,19.6313175 22.5503325,0.8377350000000001 17.465595,-0.43344 13.7370375,2.8489050000000002 10.7891625,5.1927525 -4.101464999999999,-25.90656 -5.38422,-22.003905 z",
    "m 92.2393125,325.50421500000004 10.0503225,9.6956025 5.9971499999999995,4.520227500000001 10.3476675,11.5255725 10.98762,22.50135 7.3685025,15.605895 -16.088475,1.3674 -20.749492500000002,3.2741849999999997 -11.83026,4.3319624999999995 -12.4840725,3.709695 3.46587,-21.2455425 5.38422,-22.003905 z"
  ],
  "front-delts": [
    "m 178.21873499999998,129.0289725 15.528179999999999,4.8977699999999995 21.672150000000002,0.99 12.323497499999998,5.3340525 7.5862275,11.8526475 1.6875,13.97064 -0.8974200000000001,20.751089999999998 -0.821175,17.7347025 -9.510195,-18.51951 -12.2823825,-17.09091 4.048365,-10.9594575 -2.9762625,-6.20361 -20.813715,-0.7048425 -19.015995,1.692135 -10.4468325,2.873475 -1.98855,-8.580645 6.001320000000001,-10.3070925 5.4020775,-10.60671 z",
    "m 116.38253250000001,134.644185 -18.2029575,0.4139625 -17.234272500000003,4.5178875000000005 -6.896613,9.51243 -4.2448784999999996,17.599777500000002 2.39697225,49.382145 0.30736575,-6.84708 4.2119085,-14.608987499999998 8.4066075,-10.856160000000001 8.780842499999999,-12.5574825 -2.653455,-12.72396 1.9100925000000002,-4.987815 19.28814,-2.5908375 16.0859325,-0.8459925 14.587815,3.6483375 4.148820000000001,-5.43102 -8.65569,-17.6294625 -6.8579625,-6.243847499999999 -7.1575875,7.23912 z"
  ],
  "lower-chest": [
    "m 213.5051175,168.949455 -2.650545,9.3914775 1.2459449999999999,6.689204999999999 4.06332,12.472537500000001 1.4981025000000001,9.0297225 -0.05437499999999999,9.8748225 -4.7949825,10.0267275 -6.946110000000001,6.85713 -13.3546725,1.35147 -6.1969875000000005,-3.079635 -12.192255000000001,-5.0102175 -13.3907475,-13.399627500000001 -4.1171025,-13.581937499999999 -1.5224625,-20.49936 5.3532675,-16.955685 3.8551650000000004,-6.46893 10.4468325,-2.873475 19.015995,-1.692135 20.813715,0.7048425 2.9762625,6.20361 z",
    "m 91.350555,209.3248575 3.0690825,-15.818527500000002 2.7837075,-12.118522500000001 -5.9492925,-22.91109 1.9100925000000002,-4.987815 19.28814,-2.5908375 16.0859325,-0.8459925 14.587815,3.6483375 6.816397500000001,15.3423975 1.4231925,13.544662500000001 -0.4868775,17.483805 -2.7413925,13.052475000000001 -11.7176475,11.1329625 -15.449137499999999,10.294575 -4.9004625,0.9745125 -8.064285,0.832815 -5.7536025,-2.1345075000000002 -3.0510225,-4.528485 -3.0990149999999996,-0.3199575 -3.7241625000000003,-11.407605 z"
  ],
  "abdominals": [
    "m 136.41970500000002,224.25671250000002 11.7176475,-11.1329625 6.763005000000001,5.9984175 5.8314375,-5.9691 13.3907475,13.399627500000001 12.192255000000001,5.0102175 1.4995275000000001,20.494327499999997 -2.495805,10.374022499999999 0.2996175,8.5654875 0.37311,19.65003 -3.2223525000000004,17.572395 1.895145,15.70758 -5.692807500000001,22.800345 -11.8668975,24.77199 -8.2482075,20.9468775 -14.171902499999998,-2.3415524999999997 -5.056207499999999,-15.892635000000002 -2.8308675,-13.166632500000002 -7.79016,-14.0411325 -3.25848,-25.5336825 -1.1178825,-12.563662500000001 -2.1765075,-16.417057500000002 1.944705,-33.6638175 -1.8922724999999998,-10.637235 -1.535985,-13.637272499999998 z"
  ],
  "obliques": [
    "m 100.9145025,242.4086625 7.091317500000001,-6.0500475 8.064285,-0.832815 1.2719775,9.59925 -2.6965950000000003,6.7252275 3.70641,8.695425 -1.4680725,7.220947499999999 -4.5003150000000005,0.360825 5.02008,4.20606 0.39220499999999997,9.5072775 -2.48955,6.444705000000001 3.38697,6.304852500000001 0.196515,6.118245 -4.72686,4.4208375 5.6973525,3.92253 -1.49811,7.7620875 -1.9966125,2.5730025000000003 -0.18578250000000002,10.5031125 5.76,12.8025 1.760265,9.019095 -6.260265,-2.4153450000000003 -6.61056,-10.84758 -5.7757950000000005,-5.810265 -4.6484175,-9.774705 -2.8125,-9.0727575 3.553455,-9.7343475 2.806515,-14.494132500000001 -0.41625,-17.0343525 -1.5228825000000001,-10.162882499999998 -1.3072725,-12.31854 z",
    "m 189.31743,239.43389249999998 2.2972574999999997,10.664175 -1.9248825,11.03016 1.198485,7.63689 -1.27197,9.6483225 1.7242425,8.1001575 -2.9290125,7.906065 3.0887925,9.134175 -3.822315,7.1655225 0.70281,10.551884999999999 1.4376825,9.708855 -3.7481025,12.282420000000002 -7.196594999999999,9.421927499999999 3.4142175000000003,3.6435524999999997 14.6755575,-18.9850425 7.2326175,-5.0137875 6.0539475,-12.4661325 -0.8988675,-5.7649574999999995 -6.108337500000001,-15.261705 -1.1073525,-14.483295000000002 4.6229775,-19.4277375 0.4373625,-8.043727500000001 -0.1409775,-6.1859625 0.7861874999999999,-8.320260000000001 -4.158405,-5.9329575000000006 -11.170964999999999,-1.7998874999999999 z"
  ],
  "biceps": [
    "m 235.29769499999998,204.55987500000003 2.0853975,21.065730000000002 1.522875,22.6208625 -1.992795,15.4871175 -3.27045,9.6995625 -3.7969275,-2.9848575 -4.18314,-2.8737075 -5.3816250000000005,10.010025 -7.260300000000001,2.792655 -0.6686325,-12.1884225 -0.3690075,-14.8850175 -4.141935,-10.928429999999999 4.9714125,-15.941445 4.7949975,-10.0267275 0.05437499999999999,-9.8748225 -1.4981025000000001,-9.0297225 -5.309265,-19.1617425 2.650545,-9.3914775 12.2823825,17.09091 z",
    "m 72.11493,206.81109750000002 -2.0853975,21.065730000000002 -1.522875,22.6208625 1.992795,15.4871175 3.27045,9.6995625 3.7969275,-2.9848575 4.4827650000000006,-4.97106 5.082,9.7104075 7.260300000000001,5.189625 0.6686325,-12.1884225 0.3690075,-14.8850175 2.0445824999999997,-15.422752499999998 -1.3719375,-10.7566275 -3.7241625000000003,-11.407605 -1.0274625,-8.6432025 5.852790000000001,-27.93705 -3.2958375,-10.18713 -17.18745,23.4136425 z"
  ],
  "serratus": [
    "m 96.10217999999999,229.37566500000003 3.0990149999999996,0.3199575 3.0510225,4.528485 5.7536025,2.1345075000000002 -7.091317500000001,6.0500475 -0.20849250000000003,7.638209999999999 z",
    "m 192.51178500000003,234.64254750000003 13.3546725,-1.35147 6.946110000000001,-6.85713 -1.7369400000000002,9.3254175 -3.2344725000000003,6.6160274999999995 -4.158405,-5.9329575000000006 z"
  ]
  }
  

export const classesFor = (bpart,metrics,doMap=true) => {
    const metric = doMap ? metrics[bpartToMetric[bpart]] : metrics[bpart];
    const percentileClass = (metric && !_.isBlank(metric.percentile)) ? `score-percentile-${metric.percentile}` : null;
    return _.compact([bpart,percentileClass]).join(' ');
}

export const MaleGraphic = ({ metrics }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="overlay-svg" width="310" height="800" viewBox="0 0 310 800" id="male-body-svg" role="img" aria-label="Male musculature system">
            <title>Male musculature system</title>
            <desc>Image of male muscular system with color-coded overlays for arm size, waist size, chest size, shoulder size, forearm size, calf size, hip size, and thigh size.</desc>
            <image width="310" height="800" className="body-underlay" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={maleMusculature} ></image>
            {Object.entries(malePaths).map(([bodyPart,paths]) => {
                return (
                    <React.Fragment>
                        {paths.map(path => (<path d={path} className={classesFor(bodyPart,metrics)}></path>))}
                    </React.Fragment>
                )
            })}
      </svg>
    )
}

export const FemaleGraphic = ({ metrics }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="overlay-svg"  width="310" height="800" viewBox="0 0 310 800" id="female-body-svg">
            <title>Female musculature system</title>
            <desc>Image of female muscular system with color-coded overlays for arm size, waist size, chest size, shoulder size, forearm size, calf size, hip size, and thigh size.</desc>
            <image width="310"
                    height="800" className="body-underlay" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={femaleMusculature}></image>
            {Object.entries(femalePaths).map(([bodyPart,paths]) => {
                return (
                    <React.Fragment>
                        {paths.map(path => (<path d={path} className={classesFor(bodyPart,metrics)}></path>))}
                    </React.Fragment>
                )
            })}
        </svg>
    )
}

