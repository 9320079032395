import { RecordBase, registerInflection } from 'lib/record-base';
import Fract from 'fraction.js';
import * as _ from 'lib/utilities';

export class FoodWeight extends RecordBase {
    static NAME = 'FoodWeight'

    static ASSOCS = {
        food: { type: 'belongsTo' },
        ingredients: { type: 'hasMany' },
        groceryItems: { type: 'hasMany' },
        userMeals: { type: 'hasMany', inverse: 'source' }
    }

    static MEASUREMENTS = { 
        gallon: 768, 
        cup: 48.0, 
        cups: 48.0, 
        tbsp: 3.0, 
        tablespoon: 3.0, 
        tsp: 1.0, 
        teaspoon: 1.0, 
        dash: 0.125, 
        ml: 0.2, 
        l: 200 
    }

    static WT_MMENTS = { lbs: 16.0, lb: 16.0, oz: 1.0, ounce: 1.0, ounces: 1.0, ozs: 1.0, g: 0.035, grams: 0.035 }

    static humanReadableQty(amount,measurement,food,includeGrams=false) {
        const baseMeasure = this.baseMeasure(measurement);
        const key = baseMeasure.toLowerCase();

        let mments;

        if(this.MEASUREMENTS[key]) {
            if (food.isLiquid) {
                let { dash, ml, l , ...rest } = this.MEASUREMENTS;
                mments = rest;
            } else {
                let { gallon, ml, l , ...rest } = this.MEASUREMENTS;
                mments = rest;
            }
        } else if(this.WT_MMENTS[key]) {
            mments = { ...this.WT_MMENTS }
        }

        if(mments && mments[key]) {
            const totalTsps = mments[key]*amount;
            let rational = null;
            let type;
            let best = 9999;
            let wholesOnly = false;

            for(const mment in mments) {
                const tsps = mments[mment];
                let curRational = new Fraction(totalTsps,tsps);
                const maxDenom = (mment === 'dash' || mment === 'oz' || (food.isLiquid && (mment === 'tsp' || mment === 'teaspoon'))) ? null : 4;
                let fpiece;
                if(['tsp','teaspoon','dash','oz'].includes(mment)) {
                    fpiece = curRational.fractionalPiece(0.2,maxDenom);
                } else if(['gallon','lbs','lb'].includes(mment)) {
                    fpiece = curRational.fractionalPiece(0.0625,maxDenom);
                } else {
                    fpiece = curRational.fractionalPiece(0.125,maxDenom);
                }

                if(fpiece !== null) {
                    curRational = new Fraction(fpiece.n+curRational.floor().valueOf()*fpiece.d,fpiece.d);
                    let score = fpiece.commonMultiple();
                    if( wholesOnly ) {
                        if( curRational.d === 1 && curRational.n <= 5 ) {
                            rational = curRational;
                            type = mment;
                            break;
                        }
                    } else if (score < best && (!['gallon','lbs','lb'].includes(mment) || curRational.d <= 4)) {
                        best = fpiece.commonMultiple();
                        rational = curRational;
                        type = mment;
                        if( rational.d === 1) {
                            break;
                        } else if( rational.d <= 4 ) {
                            wholesOnly = true;
                        }
                    }
                }
            }

            let adjustedMeasurement = (['cup','cups'].includes(key) && measurement.toLowerCase().includes('fl oz')) ? type : measurement.replace(baseMeasure,type)
            
            return rational.nearestFraction() + ' ' + adjustedMeasurement;
        }

        let rational = new Fraction(amount);
        const fpiece = rational.fractionalPiece(0.125);
        if(fpiece) {
            rational = new Fraction(fpiece.n+rational.floor().valueOf()*fpiece.d,fpiece.d);
        }
        return rational.nearestFraction() + ' ' + measurement;
    }

    static baseMeasure(measurement) {
        return measurement.split(/[,]*\s/)[0];
    }

    gramStr(amount) {
        const grams = Math.round(amount*this.grams);
        let gramStr = '';
        if(this.food.isLiquid) {
            let ml = this.milliliters(amount);
            ml = _.isBlank(ml) ? grams : ml;
            gramStr = `${ml}ml`
        } else {
            gramStr = `${grams}g`
        }
        return gramStr;
    }

    humanReadableQty(amount,includeName=true,includeGrams=false) {
        let qtyStr = FoodWeight.humanReadableQty(amount,this.measurement,this.food);
        if(includeGrams) {
            qtyStr = `${qtyStr} (${this.gramStr(amount)})`;
        }
        if(includeName) {
            qtyStr = qtyStr + ' ' + this.food.name;
        }
        return qtyStr;  
    }

    milliliters(amount,skip=false) {
        const key = this.measurement.split(/[,]*\s/).first;
        if(FoodWeight.MEASUREMENTS[key]) {
            return Math.round(amount*FoodWeight.MEASUREMENTS[key]*5);
        }
        if(skip) {
            return null;
        } else {
            return this.food.milliliters(amount*this.grams)
        }
    }

    sortScore() {
        return this.isDefault ? 1 : 2;
    }

    isActive(alwaysInclude=null) {
        return !this.inactive || (alwaysInclude && alwaysInclude === this.seq);
    }
}

registerInflection('foodWeight','foodWeights',FoodWeight);

class Fraction extends Fract {

    static humanReadableFractions() {
        const denoms = [2,3,4,6,8]
        let fractions = []
        denoms.forEach((denom) => {
            for(let numer=1; numer < denom; numer++) {
                fractions.push(new Fraction(numer, denom));
            }
        })

        return _.uniqWith(fractions,(f1,f2) => f1.equals(f2));
    }

    static HUMAN_READABLE = this.humanReadableFractions();

    clone() {
        return new Fraction(this.n,this.d);
    }

    fractionalPiece(leeway=0.125,maxDenom) {
        const fractions = Fraction.HUMAN_READABLE;
        let fractional = this.mod(1);
        fractional = new Fraction(fractional.n,fractional.d);
        const integer = this.floor();

        if(fractional.valueOf() > 0) {
            const origFract = _.minBy(fractions,(fract) => (fract.sub(fractional).abs().valueOf()));
            const orderedFracts = _.sortBy(fractions,fract => fract.valueOf());
            let newFract = origFract.clone();
            let done = false;
            let lastFract;
            let ind = 0;

            const invalidDenom = (denom) => (integer.equals(0) && maxDenom && denom > maxDenom);
            const percentOff = (fract) => integer.add(fract).div(this).sub(1).abs().valueOf();

            while(!done) {
                if(newFract.d <= 4 || percentOff(newFract) >= leeway) {
                    done = true;
                } else {
                    let incrFract = orderedFracts[ind];
                    if(incrFract) {
                        lastFract = newFract.clone();
                        let fracts = [];
                        for(let newIncr of [new Fraction(incrFract.n,incrFract.d),new Fraction(-incrFract.n,incrFract.d)]) {
                            let tempFract = origFract.add(newIncr);
                            tempFract = new Fraction(tempFract.n,tempFract.d);
                            if (tempFract.valueOf() >= 0 && percentOff(tempFract) < leeway) {
                                fracts.push(tempFract);
                            }
                        }
                        if (fracts.length === 0) {
                            done = true;
                        } else {
                            newFract = _.minBy(fracts,(fract) => fract.commonMultiple());
                            newFract = newFract.commonMultiple() < lastFract.commonMultiple() ? newFract : lastFract;
                        }
                    } else {
                        done = true;
                    }
                    
                }
                ind = ind + 1;
            }
            if(invalidDenom(newFract.d)) {
                newFract = null;
            }
            return newFract;
        }
        return fractional;
    }

    nearestFraction() {
        let fractional = this.mod(1);
        let integer = this.floor().valueOf();

        if(fractional.d === 1) {
            integer = integer + fractional.valueOf();
            fractional = null;
        }

        let str = '';
        if(integer > 0) {
            str = str + integer;
        }
        if(fractional && fractional.valueOf() > 0) {
            if(str.length > 0) {
                str = str + ' ';
            }
            str = str + fractional.n + '/' + fractional.d;
        }
        return str;
    }

    commonMultiple() {
        return this.n*this.d;
    }
}