import BlankErrorBoundary from 'components/BlankErrorBoundary';
import React, { Suspense, lazy } from 'react';
import * as _ from 'lib/utilities';
import { setScannerDeviceId } from 'redux/actions';
import { connect } from 'react-redux';
import { scannerDeviceIdSel } from 'redux/selectors';


//workaround to shitty Chrome bug where getUserMedia doesn't respect constraints, at least not facingMode and focusMode constraints together
async function getPreferredDeviceId() {
    try {
      // Get a list of all media devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      
      // Filter out video input devices (cameras)
      const videoDevices = devices.filter(device => (device.kind === 'videoinput'));
  
      if (videoDevices.length === 0) {
        return null; // No video devices found
      }
  
      let firstContinuousFocusDevice = null;
      let firstDevice = null;
  
      // Loop over all video devices
      for (let videoDevice of videoDevices) {
        // Set firstDevice if it's not set
        if (!firstDevice) {
          firstDevice = videoDevice.deviceId;
        }
  
        // Constraints for the current video device
        const constraints = {
          video: {
            deviceId: videoDevice.deviceId
          }
        };
  
        // Try to get the media stream for the video device
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
  
        // Stop the track after checking the capabilities
        stream.getTracks().forEach(track => track.stop());
  
        // Check if the device supports continuous focusMode
        if (capabilities.focusMode && capabilities.focusMode.includes('continuous')) {
          // Check if it's facing the environment (back camera)
          if (capabilities.facingMode && capabilities.facingMode.includes('environment')) {
            return videoDevice.deviceId; // Return if it's the preferred device (environment + continuous focus)
          }
  
          // Store the first device with continuous focusMode if no environment-facing camera is found
          if (!firstContinuousFocusDevice) {
            firstContinuousFocusDevice = videoDevice.deviceId;
          }
        }
      }
  
      // Return the first device with continuous focus, or the first device if none support continuous focus
      return firstContinuousFocusDevice || firstDevice;
  
    } catch (error) {
      console.error('Error retrieving media devices:', error);
      return null;
    }
  }
  

// Lazy-load the component
const BarcodeScanner = lazy(() => import('./BarcodeScanner'));

class BarcodeScannerWrapper extends React.Component {
    constructor(props) {
        super(props);
        const { setDeviceId, deviceId } = this.props;

        if(!_.isSafari() && _.isBlank(deviceId)) {
            getPreferredDeviceId().then(setDeviceId).catch(err => console.log(err));
        }
    }

    render() {
        const {  deviceId, setDeviceId, ...props } = this.props;

        return (
            <BlankErrorBoundary>
                <Suspense fallback={''}>
                    <BarcodeScanner components={{ audio: false }} constraints={deviceId ? { deviceId, focusMode: 'continuous' } : { focusMode: 'continuous', facingMode: 'environment' }} {...props} />
                </Suspense>
            </BlankErrorBoundary>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setDeviceId: deviceId => dispatch(setScannerDeviceId(deviceId))
})

const mapStateToProps = state => ({
    deviceId: scannerDeviceIdSel(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(BarcodeScannerWrapper);