import React from "react";

function SequentialFadeIn({ initialDelay=0, delay, children }) {
  const childArray = React.Children.toArray(children);

  return (
    <>
      {childArray.map((child, index) => {
        // Only apply styles to valid React elements (not strings, etc.)
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            style: {
              ...(child.props.style || {}),
              opacity: 0,
              animation: "fadeIn 0.5s ease forwards",
              animationDelay: (index === 0) ? `${initialDelay}ms` : `${initialDelay + (index * delay)}ms`,
            },
          });
        }
        return child;
      })}
      <style>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
    </>
  );
}

export default SequentialFadeIn;
